@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

@font-face {
  font-family: "Voyage";
  src: local("Voyage-Regular"),
    url("./fonts/Voyage-Regular.woff") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Voyage";
  src: local("Voyage-Bold"),
    url("./fonts/Voyage-Bold.woff") format("truetype");
  font-weight: bold;
}

@font-face {
    font-family: "Gill Sans";
    src: local("Gill Sans"),
      url("./fonts/Gill\ Sans.otf") format("truetype");
    font-weight: 400;
}

:root {
  --font-base: "Voyage", sans-serif;

  --primary-color: #252523;
  --secondary-color: #039e49;
  --tertiary-color: #ededed;
  --black-color: #000000;
  --lightGray-color: #cdcdcd;
  --gray-color: #969696;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}